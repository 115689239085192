
export const data = {
  header: {
    title: "Guaranteed Great Food at Everday Value Pricing!",
    callUs: "Call Us Now!<br /> 902-435-5700",
    },
  navMenu: [
    {
      title: "HOME",
      link: "/",
    },
    {
      title: "FAVORITES",
      link: "/favorites",
    },
    {
      title: "PIZZA MENU",
      link: "/pizza-menu",
    },
    {
      title: "DONAIRS",
      link: "/donairs",
    },
    {
      title: "SEAFOOD & SUBS",
      link: "/seafood-subs",
    },
    {
      title: "SPECIALS",
      link: "/specials",
    },
    {
      title: "CONTACT",
      link: "/contact",
    },
  ],
  storeInfo: {
    address: '760 Main St., (Located just off the #7 Hwy), Dartmouth, NS, B2W 3T8',
    name: 'TASTY PIZZA',
    phone: '902-435-5700',
    email: 'tastypizzadm@gmail.com',
    message: 'OPEN 7 DAYS A WEEK',
    schedule: [
       {
        days: 'Monday - Thursday',
        time: '11AM - 11PM'
      },
      {
        days: 'Friday',
        time: '11AM – 1AM'
      },
     {
        days: 'Saturday',
        time: '11AM – 12AM'
      },
      {
        days: 'Sunday',
        time: '2PM - 10PM'
      },
    ]
  },
  indexPage: {
    slider: [
      
       {
        text: "<h1>Enjoy Evening Special One Chicken Wing @$1+tax</h1><p>valid for Sunday-Monday-Tuesday-Wednesday from 8pm to till closing time(min. 10 wings order required)</p>",
         image:
          "https://res.cloudinary.com/dzoanfblg/image/upload/v1623645129/Tasty%20Pizza/P12_bdx6xf.jpg",
        alt: "Dartmouth’s Source for Pizza, Donairs, Seafood & Subs",
       },
     {
        text: "<h1>Dartmouth’s Source for Pizza, Donairs, Seafood & Subs</h1>",
        image:
          "https://res.cloudinary.com/dzoanfblg/image/upload/v1623645195/Tasty%20Pizza/Chicken_wings4_coa3eu.jpg",
        alt: "Tasty Pizza",
      },
      {
        text: "<h1>SHORT ON TIME?</h1><p>Please everyone with donairs, seafood, subs & more</p>",
        image:
          "https://res.cloudinary.com/dzoanfblg/image/upload/v1623645369/Tasty%20Pizza/fish_and_chips3_tngszx.jpg",
        alt: "Tasty Pizza. Please everyone with donairs, seafood, subs & more",
      },
    ],
    sectionOne: {
      title:
        "Tasty Pizza: Dartmouth’s Source for Pizza, Donairs, Seafood & Subs",
      description:
        "Tasty Pizza isn’t just a name to us, but a mission to make the most tasty and delicious pizza in the Cole Harbour area of Dartmouth. As a family owned business with over 20 years of experience making pizza for hungry residents, we have developed great relationships with our customers. We’ve used that experience to create a varied menu filled with favourites that appeal to every taste and every appetite. Whether you’re looking for a hearty dinner to celebrate your team’s win or something light and healthy to power you through a long afternoon of errands, Tasty Pizza has what you’re craving.",
      checkoutLink: "/favorites",
      checkouttext: "Check out Menu",
      ctaNumber: "902-435-5700",
    },
    sectionTwo: {
      title: "Pizza & Donair Take-Out, Delivery or Dine In",
      description:
        "At Tasty Pizza, we understand that taste is everything and that great taste is built from fresh, high-quality ingredients. Our pizzas start with pizza dough and famous pizza sauce that we make fresh every day, and the flavour of that foundation is enhanced by the freshness of the toppings we add. You’ll find only the freshest ingredients in our pizzas, pasta, seafood, subs, wraps, poutine, salads, burgers, nachos, fish and chips, favourite fries and more. Whether you dine in or go for delivery/take-out, your meal will arrive hot, crispy and full of flavour. And don’t forget to check out our donairs—we make the best in town!",
      checkoutLink: "/pizza-menu",
      checkouttext: "Check out Menu",
      ctaNumber: "902-435-5700",
      imageLeft: true,
    },
    sectionThree: {
      title: "Try Us Today and Make Sure to Try Our Specials!",
      description:
        "If you’ve got a hungry crowd at home or if you’re putting together a casual evening out with friends, make Tasty Pizza your first stop. You’ll find something to love for every person in your group on our menu. Eat in, take out or call for delivery.",
      checkoutLink: "/specials",
      checkouttext: "Check out our specials",
      ctaNumber: "902-435-5700",
      imageLeft: true,
      secondBelow: "We’re open 7 days a week and offer free parking.",
      belowDescription:
        "Tasty Pizza offers eat-in, carry-out and delivery to Cole Harbour, Forest Hill, Lake Echo, Main Street, Westphal, Montague and the surrounding areas.",
    },
  },
  favorites: {
    image:
      "https://res.cloudinary.com/dzoanfblg/image/upload/v1623671774/Tasty%20Pizza/nachos-4454941_1920_nkdhwe.jpg",
    pageHeading: "<h3>Check out Tasty Pizza’s Popular Menu Items</h3>",
    pageSubHeading: "What’s your favourite Tasty Pizza menu item?",
    description:
      "If you’re craving fish and chips, you’ll find the best fish and chips in Dartmouth at Tasty Pizza. We use only the freshest haddock available and you will be able to taste the difference that freshness makes. Whether you eat in, take out or choose delivery from Tasty Pizza, you can always count on the freshest, most delicious ingredients around.",
    nachos: {
      title: "Nachos",
      items: [
        {
          description:
            "Green Onions, Tomato, Black Olives, Jalapeno Peppers, Mozzarella and Cheddar Cheese, Served with Salsa and Sour Cream",
          price: "15.45",
        },
        {
          description: "Add Chicken, Hamburger, or Bacon",
          isSpace: true,
          price: "3.65",
          extraItem: "Cheese",
          extraItemPrice: "3.95",
        },
      ],
    },
    chickenWings: {
      title: "Chicken Wings",
      description: "Your Choice of Sauce: Hot,Mild,Honey Garlic,Honey Musteard,Ranch,Sweet & Sour, BBQ",
      items: [
        {
          description: "10 pcs",
          price: "12.99",
        },
        {
          description: "15 pcs",
          price: "18.95",
        },
        {
          description: "20 pcs",
          price: "24.95",
        },
        {
          description: "30 pcs",
          price: "36.95",
        },
      ],
    },
    chickenAndChips: {
      title: "Chicken & Chips",
      items: [
        {
          description: "1 pcs",
          price: "8.45",
        },
        {
          description: "2 pcs",
          price: "12.45",
        },
        {
          description: "3 pcs",
          price: "14.95",
        },
        {
          description: "Extra piece chicken",
          price: "3.75",
        },
      ],
    },
    chickenFamilyPack: {
      title: "Chicken Family Pack",
      items: [
        {
          description: "10 pcs chicken and 2 Lrg Fries, served with gravy & coleslaw",
          price: "39.95",
        },
        {
          description: "Special-2 pcs Chicken legs & Chips",
          price: "9.95",
        },
      ],
    },
    chickenNuggets: {
      title: "Chicken Nuggets & Fries",
      items: [
        {
          description: "6 pcs",
          price: "7.95",
        },
        {
          description: "12 pcs",
          price: "13.95",
        },
      ],
    },
    chickenFingers: {
      title: "Chicken Fingers & Fries",
      items: [
        {
          description: "5 pcs",
          price: "14.95",
        },
        {
          description: "10 pcs",
          price: "26.45",
        },
      ],
    },
    favouritesSides: {
      title: "Favourite Sides",
      items: [
        {
          description: "Club House Sandwich with Fries(Real Turkey)",
          price: "14.45",
        },
        {
          description: "Hot Hamburger with Gravy and Fries",
          price: "12.30",
        },
        {
          description: "Hot Turkey with Gravy and Fries",
          price: "12.30",
        },
        {
          description: "Large Fries",
          price: "5.45",
        },
        {
          description: "Large Onion Rings",
          price: "5.45",
        },
        {
          description: "12 Piece Chicken Fries",
          price: "9.45",
        },
        {
          description: "9 Piece Mozza Sticks",
          price: "10.95",
        },
        {
          description: "Cup of Gravy Sm.",
          price: "1.65",
        },
        {
          description: "Cup of Gravy Lg.",
          price: "2.85",
        },
      ],
    },
    burgerMenu: {
      heading: "Burger",
      tableHeading: [``, `Burger`, `Platter`],
      tableContent: [
        [`Hamburger`, `$6.45`, `$9.95`],
        [`Cheeseburger`, `$6.95`, `$9.95`],
        [`Chicken Burger`, `$6.95`, `$9.95`],
        [`Donair Burger`, `$7.25`, `$10.45`],
        [`Pizza Burger`, `$6.95`, `$10.45`],
        [`+ Bacon`, `$1.00`, `$1.00`],
      ],
    },
    pitaWrap: {
      title: "Pita Wraps",
      items: [
        {
          description:
            "Pizza, Donair, Cold Cut, Club, Vegetarian, Chicken, Turkey, Chicken Caesar",
          price: "10.45",
        },
      ],
    },
    poutine: {
      heading: "Poutine",
      description:
        "All Poutines are made with cheese curds and homemade gravy.",
      tableHeading: [``, `Sm.`, `Lg.`],
      tableContent: [
        [`Classic Poutine`, `$8.55`, `$12.25`],
        [`Chicken Poutine`, `-`, `$13.95`],
        [`Donair Poutine`, `-`, `$14.25`],
        [`Hamburger Poutine`, `-`, `$13.95`],
        [`Greek Poutine`, `-`, `$13.95`],
        [`Extra Cheese`, `$2.25`, `$2.95`],
        [`Extra Gravy`, `$1.75`, `$2.75`],
      ],
    },
    breadSticks: {
      title: "Bread Sticks (“Yum Yums”)",
      description:
        "Tossed in Parmesan Cheese and Served with Donair Dipping Sauce",
      items: [
        {
          description: "Small",
          price: "12.95",
        },
        {
          description: "Large",
          price: "16.45",
        },
      ],
    },
    pockets: {
      title: "Pockets",
      description:
            "Oven Baked or Deep Fried",
      items: [
        {
          description:
            "Pizza, Chicken, Meats, Vegetarian",
          price: "15.95",
        },
        {
          description:
            "Donair, Steak",
          price: "16.45",
        },
      ],
    },
    salads: {
      title: "Salads",

      items: [
        {
          description: "Caesar or Garden",
          price: "7.95",
        },
        {
          description: "Greek",
          price: "8.45",
        },
        {
          description: "Add Chicken (Crispy or Grilled)",
          price: "2.30",
        },
      ],
    },
    pasta: {
      title: "Pasta",
      items: [
        {
          description: "Lasagna with Garlic Bread",
          price: "10.45",
        },
        {
          description: "Ravioli with Garlic Bread",
          price: "10.45",
        },
      ],
    },
  },
  pizzaMenu: {
    pageHeading: "<h3>Once You Try Tasty Pizza, you’ll be hooked!</h3>",
    image:
      "https://res.cloudinary.com/dzoanfblg/image/upload/v1623696849/Tasty%20Pizza/P8_vkz6ur.jpg",
    pageSubHeading: "Tasty Pizza has the freshest pizza dough in the city",
    description:
      "It’s made every day so that the flavour and texture of the dough are perfect every time you visit. We also make our own pizza sauce, a sauce so good that many people buy it to enjoy its flavour at home. Our crisp dough and lively, well-balanced sauce are reasons why people come back again and again for their TASTY PIZZA favourites.",
    pizzaMenu: {
      heading: "Pizza",
      tableHeading: [``, `9’’ S`, `12’’ M`, `16’’ L`, `18’’ XL`],
      tableContent: [
        [`Cheese`, `$9.50`, `$12.90`, `$16.90`, `$20.95`],
        [`1 item`, `$10.70`, `$13.80`, `$17.90`, `$22.25`],
        [`2 items`, `$11.20`, `$15.75`, `$19.75`, `$23.95`],
        [`3 items`, `$11.95`, `$16.45`, `$20.45`, `$25.35`],
        [`4 items`, `$12.80`, `$17.45`, `$22.95`, `$27.45`],
        [
          `Tasty Pizza Special (5 items)`,
          `$13.50`,
          `$18.75`,
          `$24.45`,
          `$28.45`,
        ],
      ],
    },
    gourmetPizza: {
      heading: "Gourmet Specialty Pizza",
      description:
        "We offer whole wheat crust, gluten free crust, and white crust.",
       tableHeading: [``, `1`, `2`],
       tableContent: [
        [`9’’ Small`, `$13.25`,`$14.95`],
        [`12’’ Medium`, `$19.75`,`$21.50`],
        [`16’’ Large`, `$25.45`,`$27.45`],
        [`18" XL`, `$30.95`,`$32.95`],
      ],
      subItems: [
        {
          title: "1.All Dressed Pizza",
          description:
            "Pepperoni, Salami, Mushrooms, Green Peppers, Onions, Bacon, Hamburger",
        },
      
        {
          title: "1.Hawaiian Pizza",
          description: "Ham, Pineapple, Mozzarella Cheese",
        },
         {
          title: "1.Greek Pizza",
          description:
            "Tomatoes, Red Onions, Black Olives, Feta & Mozzarella Cheese, topped with Oregano and Olive Oil",
        },
         {
          title: "1.Meat Lovers Pizza",
          description:
            "Pepperoni, Salami, Bacon, Hamburger, Ham, Donair Meat, Italian Sausage",
        },
        {
          title: "1.Cheese Lovers Pizza",
          description: "Feta, Cheddar, Mozzarella & Parmesan Cheeses",
        },
        {
          title: "1.BBQ Chicken Pizza",
          description:
            "Savoury Chicken, Zesty Sauce Bland, Tomatoes, Green Peppers, Red Onions, Mushrooms, Mozza and Cheddar Cheese",
        },
        {
          title: "1.Vegetarian Pizza",
          description:
            "Mushrooms, Green Peppers, Onions, Tomatoes, Hot Peppers, Olives, Pineapple",
        },
        {
          title: "1.Canadian Classic Pizza",
          description:
            "Pepperoni, Mushrooms, Bacon, Mozza & Cheddar Cheese & a Garlic-Based Crust",
        },
        {
          title: "1.Cheeseburger Pizza",
          description:
            "Hamburger, Cheddar and Mozza Cheese, your choice of 4 vegetables",
        },
        {
          title: "2.Philly Cheese Steak Pizza",
          description:
            "Zesty Sauce, Sliced Steak, Red Onions, Tomatoes, Green Peppers, Mushrooms, topped with Mozza and Cheddar Cheese",
        },
        {
          title: "2.Donair Pizza",
          description: "Donair Meat, Onions, Tomatoes, Donair Sauce",
        },
        {
          title: "2.Bubba Pizza",
          description:
            "Garlic Spread, Donair Meat, Onions, Tomatoes, Donair Sauce",
        },
        ],
        },
    extraItems: {
      heading: "Special Toppings",
      description:
        "Zesty Chicken, Sliced Steak, Parmesan Cheese, Cheddar Cheese, Feta Cheese",
      tableContent: [
        [`9’’ Small`, `$1.95`],
        [`12’’ Medium`, `$2.85`],
        [`16’’ Large`, `$3.95`],
        [`18" XL`, `$4.95`],
      ],
    },
    extraCheese: {
      heading: "Extra Cheese",
      tableContent: [
        [`9’’ Small`, `$2.55`],
        [`12’’ Medium`, `$3.70`],
        [`16’’ Large`, `$4.90`],
        [`18" XL`, `$5.95`],
      ],
    },
      extraCheese: {
      heading: "Crust",
      tableContent: [
        [`Gluten Free Crust`, `$4.99`],
       ],
    },
      garlicMenu: {
      heading: "Garlic Fingers",
      image: "../images/thumb-7.jpg",
      description: "Served with Donair Sauce",
      tableHeading: [``, `9’’ S`, `12’’ M`, `16’’ L`, `18’’ XL`],
      tableContent: [
        [`Regular`, `$10.25`, `$13.95`, `$17.95`, `$22.45`],
        [`With Bacon`, `$11.25`, `$14.95`, `$19.95`, `$24.45`],
        [`With Donair`, `$14.95`, `$17.95`, `$21.95`, `$26.95`],
      ],
    },
  },
  donairs: {
    pageHeading: "<h3>Tasty Pizza has the Best Authentic Donair!</h3>",
    image:
      "https://res.cloudinary.com/dzoanfblg/image/upload/v1623707224/Tasty%20Pizza/DP1_jnsfel.jpg",
    donairMenu: {
      heading: "Donair",
      description: "Served on Pita Bread, topped with tomatoes, onions & donair sauce",
      tableHeading: [``, "Small", "Medium", "Large", "Super"],
      tableContent: [
        [`Regular`, `$9.25`, `$11.35`, `$13.45`, `$15.55`],
        [`Add Cheese`, `$2.00`, `$2.30`, `$2.85`, `$3.35`],
        [`Add Extra Meat`, `$3.35`, `$4.35`, `$5.35`, `$6.35`],
      ],
    },
    donairPlate: {
      title: "Donair Plates",
      description: "Served with Fries donair meat, topped with mozzarella cheese, tomatoes, onions & donair sause",
      items: [
        {
          description: "Small Donair Plate",
          price: "11.95",
        },
        {
          description: "Large Donair Plate",
          price: "15.95",
        },
      ],
    },
    donairSauce: {
      title: "Cup of Extra Sauce",
      items: [
        {
          description: "Small Donair Sauce",
          price: "2.25",
        },
        {
          description: "Large Donair Sauce",
          price: "3.25",
        },
        {
          description: "Extra Large Donair Sauce",
          price: "4.95",
        },
      ],
    },
    donairPogos: {
      title: "Donair Pogo's",
       description: "Served with donair sause",
      items: [
        {
          description: "1 Donair Pogo",
          price: "3.45",
        },
        {
          description: "Dozen",
          price: "38.95",
        },
      ],
    },
    donairEggRolls: {
      title: "Donair Egg Rolls",
      description: "Served with donair sause",
      items: [
        {
          description: "1 Donair Egg Roll",
          price: "2.50",
        },
        {
          description: "Dozen",
          price: "28.00",
        },
      ],
    },
  },
  seafoodAndSubs: {
    pageHeading: "<h3>A variety of seafood and subs at Tasty Pizza!</h3>",
    image:
      "https://res.cloudinary.com/dzoanfblg/image/upload/v1623724793/Tasty%20Pizza/fish_and_chips3_wajwio.jpg",
    familyPack: {
      title: "Family Pack",
      items: [
        {
          description:
            "10 pcs fish & 2 Lrg Fries with L.g. cup of gravy, Coleslaw & Tarter sauce",
          price: "39.95",
        },
      ],
    },
    fishAndChips: {
      title: "Fish & Chips-Fresh Haddock",
      items: [
        {
          description: "1 pc",
          price: "8.95",
        },
        {
          description: "2 pcs",
          price: "12.95",
        },
        {
          description: "3 pcs",
          price: "14.95",
        },
        {
          description: "Extra pc",
          price: "3.95",
        },
      ],
    },
    other: {
      title: "Other Tasty Seafood",
      items: [
        {
          description: "Scallops & Chips-10 pcs Scallops",
          price: "13.45",
        },
        {
          description: "Clams & Chips-2 cups Clams",
          price: "13.45",
        },
        {
          description: "Shrimps & Chips-12 pcs Shrimps",
          price: "13.45",
        },
        {
          description: "Seafood Platter-1 piece fish & fries 5 Scallops, 5 Shrimps, Clams",
          price: "17.45",
        },
         {
          description: "Extra Coleslaw",
          price: "S.m. 1.75 L.g. 2.95",
        },
      ],
    },
    subMenu: {
      heading: "Subs",
      tableHeading: [``, `Small`, `Large`],
      tableContent: [
        ["Cold Cut", "$9.25", "$11.45"],
        ["Salami & Ham", "$9.25", "$11.45"],
        ["Bubba", "$9.95", "$12.75"],
        ["Pizza", "$9.25", "$11.45"],
        ["Vegetarian", "$9.25", "$11.45"],
        ["Real Turkey", "$9.45", "$11.45"],
        ["Garlic Bread", "$4.95", "$5.95"],
        ["Steak", "$10.25", "$13.25"],
        ["Donair", "$10.25", "$13.25"],
        ["Club", "$10.25", "$12.75"],
        ["Super", "$10.95", "$14.75"],
        ["+ Extra Cheese", "$2.45", "$3.45"],
        ["+ Extra Meat", "$3.45", "$4.45"],
      ],
    },
  },
  specials: {
    pageHeading: "<h3>Tasty Specials</h3>",
    image:
      "https://res.cloudinary.com/dzoanfblg/image/upload/v1623704439/Tasty%20Pizza/P13_ipclal.jpg", 
    specialsItems: [
      
       {
        title: "Large 16’’ Pizza",
        items: [
          
          {
            description: "with 4 Different Toppings",
            price: "20.95 + tax",
          },
          {
            description: "add a Medium Garlic Fingers with dipping sauce",
            price: "12.45 + tax",
          },
        ],
      },
       {
        title: "2 Medium Pizza",
        items: [
          {
            description: "with 4 different toppings",
            price: "32.95 + tax",
          },
        ],
      },
      {
        title: "Medium 12’’ Pizza",
        items: [
          {
            description: "with 4 different toppings",
          },
          {
            description: "And Medium 12’’ Garlic Fingers with donair sauce",
            price: "28.95 + tax",
          },
        ],
      },
      {
        title: "Donair Egg Rolls & Pogo's",
        items: [
          {
            description: "6 Donair Egg Rolls",
            price: "13.95 + tax",
          },
          {
            description: "6 Donair Pogo's",
            price: "19.50 + tax",
          },
        ],
      },
      {
        title: "2 Large Subs",
        items: [
          {
            description: "Cold Cut, Pizza or Real Turkey",
            price: "22.45 + tax",
          },
        ],
      },
      
    ],
  },
};
